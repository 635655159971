import { Modal, Button } from 'react-bootstrap';
import styled from 'styled-components';

const Buttons = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 0.5rem;

	@media (max-width: 576px) {
		flex-direction: column;
	}
`;

export default function Confirmation({ show, setShow, message, confirmFunction, buttonType = 'primary' }) {
	return (
		<Modal show={show} onHide={() => setShow(false)} backdrop='static'>
			<Modal.Header className='justify-content-center'>
				<Modal.Title>Are you sure?</Modal.Title>
			</Modal.Header>
			<Modal.Body className='text-center'>
				<p>Please confirm that you would like to {message}</p>
				<Buttons>
					<Button variant={buttonType} onClick={() => confirmFunction()}>
						Yes, go ahead. <i className='fas fa-check-circle'></i>
					</Button>
					<Button variant='secondary' onClick={() => setShow(false)}>
						Nope, go back. <i className='fas fa-times-circle'></i>
					</Button>
				</Buttons>
			</Modal.Body>
		</Modal>
	);
}

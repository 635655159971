import { useGoogleLogin } from '@react-oauth/google';
import logo from 'img/app/appfolio_logo.svg';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { checkIsSignedIn, getGlobalUser, checkLocalUser, handleSignIn } from 'utils/Auth';

import { useLogin } from 'utils/UserContext';

const Component = styled.main`
	display: grid;
	place-items: center;
	height: 400px;
`;

const ErrorMessage = styled.div`
	justify-self: stretch;
	align-self: start;
	padding: 1rem;
	background: var(--bs-danger);
	color: white;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 0.5rem;

	i {
		opacity: 0.6;
	}
`;

const LoginButton = styled.div`
	text-align: center;
`;

const Logo = styled.img`
	width: 100px;
	height: 100px;
	object-fit: cover;
	cursor: pointer;
	transition: 0.2s;
	border-radius: 50%;
	margin: 2rem auto 0;

	&:hover,
	&:focus-visible {
		box-shadow: 0 0 0 1rem var(--primaryOutline2), 0 0 0 1.5rem var(--primaryOutline1);
	}
`;

const Login = () => {
	// State
	const [error, setError] = useState('');
	const [cookies] = useCookies();

	// Hooks
	const handleLogin = useGoogleLogin({
		onSuccess: res => handleSuccess(res),
		onError: res => handleError(res),
		hosted_domain: process.env.REACT_APP_LOGIN_DOMAIN
	});
	const { setUser } = useLogin();
	const navigate = useNavigate();
	const location = useLocation();
	const { from } = location.state || { from: { pathname: '/' } };
	const [searchParams, setSearchParams] = useSearchParams();

	// Handlers
	const handleSuccess = async res => {
		setError('');
		const { access_token } = res;
		try {
			const profile = await handleSignIn(access_token);
			const user = await getGlobalUser(profile);
			await checkLocalUser(user);
			setUser(user);
			navigate(from, { replace: true });
		} catch (error) {
			setError(error.message);
			setUser(null);
		}
	};

	const handleError = res => {
		setError(res.error);
		setUser(null);
	};

	// Effects
	useEffect(() => {
		const checkCookie = async jwt => {
			const profile = await checkIsSignedIn(jwt);
			const user = await getGlobalUser(profile);
			await checkLocalUser(user);
			setUser(user);
			navigate(from, { replace: true });
		};

		const profileJwt = cookies[process.env.REACT_APP_COOKIE_NAME];
		if (profileJwt) checkCookie(profileJwt);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (searchParams.get('error') === 'Unauthorized') {
			setError('Unauthorized. Your session may have expired, please sign in again.');
			setSearchParams('');
		}
	}, [searchParams, setSearchParams]);

	return (
		<Component>
			{error && (
				<ErrorMessage>
					<i className='fas fa-times-circle'></i> Error - {error}
				</ErrorMessage>
			)}

			<LoginButton>
				<h5 className='text-xl font-semibold'>Click to sign in with AppFolio SSO</h5>
				<Logo src={logo} alt='Sign in with AppFolio SSO' title='Sign in with AppFolio SSO' width='100' onClick={() => handleLogin()} />
			</LoginButton>
		</Component>
	);
};

export default Login;

import Helmet from 'react-helmet';
import { useLogin } from 'utils/UserContext';
import { useMessage } from 'utils/MessageContext';
import { Formik } from 'formik';
import NewForm from './NewForm';
import dayjs from 'dayjs';
import { calculateStageDates } from 'utils/Stages';
import { fetcher } from 'utils/Fetch';
import { useNavigate } from 'react-router-dom';
import PageHeader from 'components/shared/PageHeader';

export default function New() {
	// Hooks
	const { user } = useLogin();
	const { setMessage } = useMessage();
	const navigate = useNavigate();

	// Initial Values
	const initialValues = {
		appfolio_contact: user.email,
		assets: [],
		assets_url: '',
		company_contact: '',
		company_name: '',
		linked_plan_id: '',
		number_of_sites: 'Single Site',
		on_hold: false,
		site_id: '',
		product_tier: 'S1',
		properties: [],
		property_name: '',
		property_details: [],
		purpose: 'Company Site',
		stage: 'Welcome',
		target_dates: {
			start: dayjs().format('YYYY-MM-DD')
		},
		website_url: ''
	};

	// Handlers
	const handleSubmit = async values => {
		// Destructure values
		const { target_dates, ...rest } = values;

		// Calculate dates
		const new_target_dates = calculateStageDates(values.target_dates.start);

		// POST to API
		try {
			const planOwner = await fetcher(`/api/v1/users/${user._id}`);
			if (!planOwner) throw new Error('User not found.');

			// Form body
			const body = {
				...rest,
				calendly: {
					is_enabled: true,
					booking_url: planOwner?.calendly?.booking_url || ''
				},
				target_dates: new_target_dates,
				actual_dates: {
					start: new_target_dates.start,
					collect: null,
					build: null,
					review: null,
					live: null
				}
			};
			const plan = await fetcher('/api/v1/plans/new', { method: 'POST', body: JSON.stringify(body) });
			if (!plan) throw new Error('Error creating project plan.');
			navigate(`/plan/${plan.id}`);
		} catch ({ message }) {
			setMessage({ variant: 'danger', text: message });
		}
	};

	return (
		<>
			<Helmet>
				<title>New Project Plan | Project Plan Tool</title>
			</Helmet>
			<PageHeader heading='New Project Plan' subheading='Create a new project plan.' />
			<Formik initialValues={initialValues} onSubmit={values => handleSubmit(values)}>
				<NewForm />
			</Formik>
		</>
	);
}

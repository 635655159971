import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Form, Button } from 'react-bootstrap';
import { Field, Form as FormikForm, useFormikContext } from 'formik';
import Timeline from './Timeline';
import Assets from './Assets/Assets';
import { StickyButtons } from 'components/shared/ButtonBar';
import { Link, useParams } from 'react-router-dom';
import { tidyUrl } from 'utils/Tidy';
import { Typeahead, Highlighter } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import useSWR from 'swr';
import { useLogin } from 'utils/UserContext';
import dayjs from 'dayjs';
import ObjectID from 'bson-objectid';
import { fetcher } from 'utils/Fetch';
import { useMessage } from 'utils/MessageContext';
import Scheduling from './Scheduling';

const QuickSettings = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 1rem;
	align-items: end;

	@media (max-width: 991px) {
		grid-template-columns: 100%;
	}
`;

const Toggle = styled.div`
	padding: 1rem;
	background: #e9ecef;
	border-radius: 0.5rem;
`;

const ProjectDetails = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 1.5rem;

	@media (max-width: 991px) {
		grid-template-columns: 100%;
	}
`;

const FieldWrapper = styled.div`
	margin: 1.5rem 0;
	display: grid;
`;

const CustomButton = styled(Button)`
	min-width: 0 !important;
	padding: 0;
	margin: 0 !important;
	width: 2.5rem;
	height: 2.5rem;
`;

const Fields = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr) 2rem;
	gap: 1rem;
	align-items: center;
	padding: 0.5rem 0;

	& > * {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
`;

const PropertyField = styled(Fields)`
	border-top: 1px solid #ddd;
`;

const Remove = styled.button`
	all: unset;
	color: var(--bs-danger);
`;

const EditForm = ({ setShowConfirmation, setShowDropboxPrompt, mutatePlan }) => {
	// State
	const [toggleLinkedPlan, setToggleLinkedPlan] = useState(false);

	// Hooks
	const { user } = useLogin();
	const { values, setFieldValue } = useFormikContext();
	const { id } = useParams();
	const { data: plans } = useSWR(toggleLinkedPlan ? `/api/v1/plans/user/${user.email}` : null, `/api/v1/plans/user/${user.email}`);
	const options = plans ? plans.map(plan => ({ id: plan._id, name: plan.company_name })).filter(plan => plan.id !== id) : [];
	const { setMessage } = useMessage();

	// Effects
	useEffect(() => {
		if (values.linked_plan_id) {
			setToggleLinkedPlan(true);
		}
	}, [values.linked_plan_id, setToggleLinkedPlan]);

	// Handlers
	const handleAssetsUrlChange = e => {
		const { value } = e.target;
		setFieldValue('assets_url', value);
		if (value.includes('dropbox.com/sh/')) {
			setShowDropboxPrompt(true);
		}
	};

	const handleSetAssets = assets => {
		setFieldValue('assets', assets);
	};

	const handleAddProperty = async () => {
		if (values.property_name) {
			const _id = ObjectID().toHexString();
			setFieldValue('properties', [...values.properties, { _id, property_name: values.property_name, website_url: values.website_url, preview_url: values.preview_url }]);
			setFieldValue('property_name', '');
			setFieldValue('website_url', '');
			setFieldValue('site_id', '');
		}
	};

	const handleRemoveProperty = _id => {
		const filtered = values.properties.filter(property => property._id !== _id);
		setFieldValue('properties', filtered);
	};

	const handleSelectPlan = async e => {
		if (e.length > 0) {
			const { id: linked_plan_id } = e[0];
			try {
				await fetcher('/api/v1/plans/link', { method: 'PUT', body: JSON.stringify({ original_plan_id: id, linked_plan_id }) });
				await mutatePlan();
			} catch (error) {
				setMessage({ type: 'danger', text: error.message });
			}
		}
	};

	const handleToggleLinkedPlan = async () => {
		// If we're turning it off
		if (toggleLinkedPlan) {
			try {
				await fetcher('/api/v1/plans/unlink', { method: 'PUT', body: JSON.stringify({ original_plan_id: id, linked_plan_id: values.linked_plan_id }) });
				await mutatePlan();
			} catch (error) {
				setMessage({ type: 'danger', text: error.message });
			}
			setFieldValue('linked_plan_id', '');
		}
		setToggleLinkedPlan(!toggleLinkedPlan);
	};

	const handleStageChange = e => {
		const { value } = e.target;
		setFieldValue('stage', value);
		setFieldValue(`actual_dates.${value.toLowerCase()}`, dayjs().format('YYYY-MM-DD'));
	};

	return (
		<FormikForm>
			<h3 className='mb-3 text-2xl font-bold'>Quick Settings</h3>
			<QuickSettings>
				<Toggle>
					<Field as={Form.Switch} id='hold-switch' label='Put this project on an indefinite hold' className='m-0' checked={values.on_hold} name='on_hold' />
				</Toggle>
				<div>
					<Form.Label>Current Stage</Form.Label>
					<Field as={Form.Select} name='stage' onChange={e => handleStageChange(e)}>
						<option value='Welcome'>Welcome & Schedule</option>
						<option value='Collect'>Collect Assets</option>
						<option value='Build'>Build Site</option>
						<option value='Review'>Review & Revise</option>
						<option value='Live'>Live</option>
					</Field>
				</div>
				<Toggle>
					<Form.Switch id='link-switch' label='Link this Project Plan to another' className='m-0' checked={toggleLinkedPlan} onChange={() => handleToggleLinkedPlan()} />
				</Toggle>
				{toggleLinkedPlan && (
					<div>
						<Form.Label>Linked Plan ID</Form.Label>
						<Typeahead
							id='link-plan'
							labelKey='id'
							filterBy={['name', 'id']}
							options={options}
							renderMenuItemChildren={(option, props) => (
								<>
									<Highlighter search={props.text}>{option.name}</Highlighter>
									<div>
										<small className='text-secondary'>{option.id}</small>
									</div>
								</>
							)}
							placeholder='Search for a company name or plan ID...'
							defaultSelected={values.linked_plan_id ? [values.linked_plan_id] : []}
							minLength={2}
							onChange={e => handleSelectPlan(e)}
						/>
					</div>
				)}
			</QuickSettings>
			<hr className='my-4 text-gray-200' />
			<h3 className='mb-3 text-2xl font-bold'>Project Details</h3>
			<ProjectDetails>
				<div>
					<Form.Label>Company Name</Form.Label>
					<Field as={Form.Control} name='company_name' />
				</div>
				<div>
					<Form.Label>Product Tier</Form.Label>
					<Field as={Form.Select} name='product_tier'>
						<option>S1</option>
						<option>S2</option>
						<option>SE</option>
						<option>Custom</option>
					</Field>
				</div>
				<div>
					<Form.Label>Company Contact</Form.Label>
					<Field as={Form.Control} name='company_contact' />
				</div>
				<div>
					<Form.Label>Website Purpose</Form.Label>
					<Field as={Form.Select} name='purpose'>
						<option>Company Site</option>
						<option>Single Property</option>
						<option disabled={values.product_tier !== 'S1'}>Community Association</option>
					</Field>
				</div>
				<div>
					<Form.Label>Project Owner</Form.Label>
					<Field as={Form.Control} name='appfolio_contact' />
				</div>
				<div>
					<Form.Label>Number of Sites</Form.Label>
					<Field as={Form.Select} name='number_of_sites'>
						<option>Single Site</option>
						<option>Multi-Site Pack</option>
					</Field>
				</div>
				{values.number_of_sites === 'Single Site' && (
					<>
						<div>
							<Form.Label>Live Website URL</Form.Label>
							<Field as={Form.Control} type='url' name='website_url' />
						</div>

						<div>
							<Form.Label>Duda Site ID</Form.Label>
							<Field as={Form.Control} name='site_id' minLength={8} maxLength={8} />
						</div>
					</>
				)}
			</ProjectDetails>
			<hr className='my-4 text-gray-200' />
			<h3 className='mb-3 text-2xl font-bold'>Timeline</h3>
			<Timeline on_hold={values.on_hold} target_dates={values.target_dates} actual_dates={values.actual_dates} stage={values.stage} />
			<hr className='my-4 text-gray-200' />
			<h3 className='mb-3 text-2xl font-bold'>Scheduling</h3>
			<Scheduling plan={values} mutate={mutatePlan} />
			<hr className='my-4 text-gray-200' />
			{values.number_of_sites === 'Multi-Site Pack' && (
				<>
					<h3 className='mb-3 text-2xl font-bold'>Properties</h3>
					<FieldWrapper>
						<Fields>
							<div className='fw-bold'>Property Name</div>
							<div className='fw-bold'>Live Website URL</div>
							<div className='fw-bold'>Preview Site URL</div>
						</Fields>
						{values.properties.map(property => (
							<PropertyField key={property._id}>
								<div>{property.property_name}</div>
								<div>{tidyUrl(property.website_url)}</div>
								<div>{property.site_id}</div>
								<Remove type='button'>
									<i className='fas fa-trash-alt' onClick={() => handleRemoveProperty(property._id)}></i>
								</Remove>
							</PropertyField>
						))}
						<Fields>
							<Field as={Form.Control} type='text' name='property_name' />
							<Field as={Form.Control} type='url' name='website_url' />
							<Field as={Form.Control} name='site_id' minLength={8} maxLength={8} />
							<CustomButton className='default-style' type='button' onClick={() => handleAddProperty()}>
								<i className='fas fa-plus'></i>
							</CustomButton>
						</Fields>
					</FieldWrapper>
					<hr className='my-4 text-gray-200' />
				</>
			)}
			<h3 className='mb-3 text-2xl font-bold'>Assets</h3>
			<Form.Group className='mb-3'>
				<Form.Label>Asset Upload URL</Form.Label>
				<div className='d-flex flex-nowrap'>
					<Field as={Form.Control} name='assets_url' onChange={e => handleAssetsUrlChange(e)} value={values.assets_url || ''} type='url' placeholder='eg. www.dropbox.com/request/request_id' pattern='https://www.dropbox.com/.+' />
					<a href='https://www.dropbox.com/requests' target='_blank' rel='noopener noreferrer' className='text-sky-600'>
						{!values.assets_url && (
							<Button className='default-style ms-2' title='Create a new file request'>
								<i className='fas fa-external-link-alt'></i>
							</Button>
						)}
					</a>
				</div>
			</Form.Group>
			<Form.Group className='mb-3'>
				<Form.Label>Assets Checklist</Form.Label>
				<Assets assets={values.assets} setAssets={handleSetAssets} />
			</Form.Group>
			<StickyButtons>
				<Button type='submit'>
					Save Plan <i className='fas fa-save'></i>
				</Button>
				<Button variant='danger' onClick={() => setShowConfirmation(true)}>
					Delete Plan <i className='fas fa-trash-alt'></i>
				</Button>
				<Button as={Link} variant='secondary' to={`/plan/${id}`}>
					Cancel <i className='fas fa-times-circle'></i>
				</Button>
			</StickyButtons>
		</FormikForm>
	);
};

export default EditForm;

import Helmet from 'react-helmet';
import { Button } from 'react-bootstrap';
import { useParams, Link } from 'react-router-dom';
import LoadingOverlay from 'components/shared/LoadingOverlay';
import Timeline from './Timeline/Timeline';
import WhatWeNeed from './WhatWeNeed';
import WhatWeAreDoing from './WhatWeAreDoing';
import DueDate from './DueDate';
import PlanStatus from 'components/shared/PlanStatus';
import { tidyUrl, tidyDate } from 'utils/Tidy';
import { calculateDueDate, calculateStatus } from 'utils/Stages';
import dayjs from 'dayjs';
import styled from 'styled-components';
import MultiSite from './MultiSite';
import useSWR from 'swr';
import { ButtonBar, BarButton } from 'components/shared/ButtonBar';
import { getPreviewUrl } from 'utils/Sites';
import PageHeader from 'components/shared/PageHeader';
import { useLogin } from 'utils/UserContext';
import Events from 'components/shared/Events/Events';

const Header = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: start;
	gap: 1rem;

	@media (max-width: 991px) {
		flex-direction: column;
		align-items: stretch;
	}
`;

const Details = styled.div`
	overflow: hidden;

	& > * {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
`;

const Panel = styled.div`
	background: white;
	padding: 1.5rem;
	border-radius: 0.5rem;
	box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);
	display: grid;
	gap: 1rem;
	align-items: start;
`;

const Wrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	margin: 2rem 0 0;
	align-items: start;

	@media (max-width: 991px) {
		grid-template-columns: 100%;
	}
`;

const Column = styled.div`
	display: grid;
	gap: 1rem;
`;

const Label = styled.div`
	font-weight: bold;
`;

const Value = styled.div`
	color: var(--bs-secondary);
`;

const Plan = () => {
	// Hooks
	const { id } = useParams();
	const { data: plan, mutate } = useSWR(`/api/v1/plans/${id}`);
	const { user: designer } = useLogin();

	// Destructure data
	const { company_name, company_contact, on_hold, assets_url, stage, target_dates, actual_dates, site_id, website_url, product_tier, number_of_sites, properties, appfolio_contact, purpose, linked_plan_id, calendly } = { ...plan };
	const { booking_url, events, is_enabled } = { ...calendly };
	const eventsWithPlanId = events?.map(event => ({ ...event, plan_id: id }));
	const { start, live } = { ...target_dates };

	// Calculations
	const dueDate = calculateDueDate(stage, target_dates);
	const status = calculateStatus(on_hold, stage, dueDate);
	const currentBuildLength = dayjs(live).diff(dayjs(start), 'day');

	return (
		<>
			<Helmet>
				<title>{company_name || 'Company name'} | Project Plan</title>
			</Helmet>
			{!plan ? (
				<LoadingOverlay />
			) : (
				<>
					<PageHeader heading={company_name} />
					<Header>
						<Details>
							<p className='m-1 text-secondary'>
								{company_contact ? (
									<>
										<i className='fas fa-check-circle text-success me-1'></i>
									</>
								) : (
									<i className='fas fa-times-circle text-danger me-1'></i>
								)}{' '}
								Company Contact:{' '}
								<a href={`mailto:${company_contact}`} className='text-sky-600'>
									{company_contact}
								</a>
							</p>
							{number_of_sites === 'Single Site' && (
								<>
									<p className='m-1 text-secondary'>
										{site_id ? (
											<>
												<i className='fas fa-check-circle text-success me-1'></i>
											</>
										) : (
											<i className='fas fa-times-circle text-danger me-1'></i>
										)}{' '}
										Preview Site URL:{' '}
										{site_id && (
											<a href={getPreviewUrl(site_id)} target='_blank' rel='noopener noreferrer' className='text-sky-600'>
												{tidyUrl(getPreviewUrl(site_id))}
											</a>
										)}
									</p>
									<p className='m-1 text-secondary'>
										{website_url ? (
											<>
												<i className='fas fa-check-circle text-success me-1'></i>
											</>
										) : (
											<i className='fas fa-times-circle text-danger me-1'></i>
										)}{' '}
										Live Website URL:{' '}
										<a href={website_url} target='_blank' rel='noopener noreferrer' className='text-sky-600'>
											{tidyUrl(website_url)}
										</a>
									</p>
								</>
							)}
							<p className='m-1 text-secondary'>
								{assets_url ? (
									<>
										<i className='fas fa-check-circle text-success me-1'></i>
									</>
								) : (
									<i className='fas fa-times-circle text-danger me-1'></i>
								)}{' '}
								Dropbox URL:{' '}
								<a href={assets_url} target='_blank' rel='noopener noreferrer' className='text-sky-600'>
									{tidyUrl(assets_url)}
								</a>
							</p>
							<p className='m-1 text-secondary'>
								{booking_url ? (
									<>
										<i className='fas fa-check-circle text-success me-1'></i>
									</>
								) : (
									<i className='fas fa-times-circle text-danger me-1'></i>
								)}{' '}
								Calendly URL:{' '}
								<a href={booking_url} target='_blank' rel='noopener noreferrer' className='text-sky-600'>
									{tidyUrl(booking_url)}
								</a>
							</p>
						</Details>
						<ButtonBar>
							{purpose !== 'Community Association' ? (
								<>
									<BarButton to={`/brief/${id}`}>
										Creative Brief <i className='fas fa-angle-right'></i>
									</BarButton>
									{number_of_sites === 'Single Site' && (
										<BarButton to={`/site-genie/${id}`}>
											Site Genie <i className='fas fa-angle-right'></i>
										</BarButton>
									)}
									<BarButton to={`/properties/${id}`}>
										Properties <i className='fas fa-angle-right'></i>
									</BarButton>
								</>
							) : (
								<BarButton to={`/association/${id}`}>
									Association Info <i className='fas fa-angle-right'></i>
								</BarButton>
							)}
							<BarButton to={`/view/${id}`} target='_blank' rel='noopener noreferrer'>
								Customer Link <i className='fas fa-external-link-alt'></i>
							</BarButton>
						</ButtonBar>
					</Header>
					{number_of_sites === 'Multi-Site Pack' && <MultiSite properties={properties} />}
					<Wrapper>
						<Column style={{ alignSelf: 'start' }}>
							<Panel>
								<h4 className='text-2xl font-semibold'>Project Status</h4>
								<div>
									<Label>Project Status:</Label>
									<Value>
										{!on_hold ? (
											<PlanStatus stage={stage} dueDate={dueDate} showDelay={true} />
										) : (
											<>
												<i className='fas fa-exclamation-circle text-danger me-1'></i> On Hold
											</>
										)}
									</Value>
								</div>
								<div>
									<Label>Target Go-Live Date:</Label>
									<Value>{tidyDate(live)}</Value>
								</div>
								<div>
									<Label>Current Build Length:</Label>
									<Value>{currentBuildLength} days</Value>
								</div>
								<div>
									<Label>Current Stage:</Label>
									<Value>{stage}</Value>
								</div>
							</Panel>
							<Panel>
								<h4 className='text-2xl font-semibold'>Project Details</h4>
								<div>
									<Label>Project Owner:</Label>
									<Value>
										<a href={`mailto:${appfolio_contact}`} className='text-sky-600'>
											{appfolio_contact}
										</a>
									</Value>
								</div>
								{linked_plan_id && (
									<div>
										<Label>Linked Plan ID:</Label>
										<Value>
											<Link to={`/plan/${linked_plan_id}`}>{linked_plan_id}</Link>
										</Value>
									</div>
								)}
								<div>
									<Label>Product Tier:</Label>
									<Value>{product_tier}</Value>
								</div>
								<div>
									<Label>Website Purpose:</Label>
									<Value>{purpose}</Value>
								</div>
								<div>
									<Label>Number of Sites:</Label>
									<Value>{number_of_sites}</Value>
								</div>
							</Panel>
							<Button as={Link} to={`/edit/${id}`} className='mt-3' style={{ justifySelf: 'start' }}>
								Edit Plan <i className='fas fa-edit'></i>
							</Button>
						</Column>
						<div>
							<Timeline target_dates={target_dates} actual_dates={actual_dates} stage={stage} dueDate={dueDate} status={status} />
						</div>
						<div className='bg-white rounded-lg shadow'>
							{!on_hold ? (
								<div className='divide-y divide-gray-100'>
									{is_enabled && (
										<div className='p-4 space-y-2'>
											<h4 className='text-2xl font-semibold'>Events:</h4>
											<Events events={eventsWithPlanId} mutate={mutate} />
										</div>
									)}
									<div className='p-4 space-y-2'>
										<h4 className='text-2xl font-semibold'>What we are doing:</h4>
										<WhatWeAreDoing stage={stage} />
									</div>
									<div className='p-4 space-y-2'>
										<h4 className='text-2xl font-semibold'>What we need:</h4>
										<WhatWeNeed plan={plan} designer={designer} showCalendly={false} />
									</div>
									<div className='p-4 space-y-2'>
										<h4 className='text-2xl font-semibold'>Due date{stage === 'Review' && 's'}:</h4>
										<DueDate stage={stage} due_date={dueDate} status={status} target_dates={target_dates} number_of_sites={number_of_sites} />
									</div>
								</div>
							) : (
								<div>
									<h5 className='mb-2 text-lg font-semibold'>
										<i className='fas fa-exclamation-circle text-danger me-1'></i> This project has been put on hold.
									</h5>
									<div>
										Please contact{' '}
										<a href={`mailto:${process.env.REACT_APP_ONBOARDING_MANAGER_EMAIL}`} className='text-sky-600'>
											{process.env.REACT_APP_ONBOARDING_MANAGER_EMAIL}
										</a>{' '}
										to continue the development of your website.
									</div>
								</div>
							)}
						</div>
					</Wrapper>
				</>
			)}
		</>
	);
};

export default Plan;

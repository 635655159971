import { useState, useEffect } from 'react';
import PageHeader from 'components/shared/PageHeader';
import styled from 'styled-components';
import { Form } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useParams, useNavigate } from 'react-router-dom';
import logo from 'img/app/appfolio_logo.svg';
import LoadingOverlay from 'components/shared/LoadingOverlay';
import { fetcher } from 'utils/Fetch';

// Steps
import Welcome from './Steps/Welcome';
import PreliminaryInfo from './Steps/PreliminaryInfo';
import TemplateDesign from './Steps/TemplateDesign';
import FinalApprover from './Steps/FinalApprover';
import Complete from './Steps/Complete';
import useSWR from 'swr';

const Wrapper = styled.div`
	max-width: 1280px;
	margin: 0 auto;

	.row {
		margin-bottom: 1rem;
	}

	h4,
	p {
		margin-bottom: 1rem;
	}

	.form-label {
		font-weight: bold;
	}

	.custom-control {
		margin-bottom: 0.5rem;
	}
`;

const Logo = styled.img`
	width: 75px;
	margin-right: 2rem;

	@media (max-width: 767px) {
		width: 50px;
		margin-right: 1rem;
	}
`;

const Survey = () => {
	// Component state
	const [formResponse, setFormResponse] = useState({});
	const [currentStep, setCurrentStep] = useState(1);
	const [isLoading, setIsLoading] = useState(true);

	// Hooks
	const { id } = useParams();
	const navigate = useNavigate();
	const { data: jwt, error: jwtError } = useSWR('/auth/jwt');
	const { data: survey, error: surveyError } = useSWR(jwt ? `/api/v1/associations/survey/${id}` : null);

	// Effects
	useEffect(() => {
		if (survey) {
			setFormResponse(survey);
			if (survey?.submitted) {
				navigate('/expired', { state: { error: { message: 'This link has expired as the survey has already been submitted.' } } });
			}
		}
	}, [navigate, survey]);

	useEffect(() => {
		if (jwt && survey) {
			setIsLoading(false);
		}
	}, [jwt, survey]);

	useEffect(() => {
		if (jwtError || surveyError) {
			navigate('/error', { state: { from_public_page: true } });
		}
	}, [jwtError, navigate, surveyError]);

	// Next template step
	const nextStep = () => {
		setCurrentStep(currentStep + 1);
	};

	// Previous template step
	const prevStep = () => {
		setCurrentStep(currentStep - 1);
	};

	// Handle input change
	const handleChange = e => {
		// Destructure target
		const { name, value, type, checked } = e.target;
		if (type === 'checkbox') {
			if (checked) {
				setFormResponse({ ...formResponse, [name]: value });
			} else {
				const newResponse = { ...formResponse };
				delete newResponse[name];
				setFormResponse(newResponse);
			}
		} else {
			setFormResponse({ ...formResponse, [name]: value });
		}
	};

	// Form submit
	const handleSubmit = async e => {
		// Prevent page refresh
		e.preventDefault();

		setIsLoading(true);

		// POST to API
		try {
			const data = await fetcher(`/api/v1/associations/survey/${id}`, { method: 'PUT', body: JSON.stringify({ ...formResponse, customer_response: true, submitted: new Date() }) });
			setFormResponse(data);
			setCurrentStep('Complete');
		} catch (error) {
			navigate('/error', { state: { from_public_page: true } });
		} finally {
			setIsLoading(false);
		}
	};

	// Render
	return (
		<div className='p-3 p-md-5'>
			{!isLoading ? (
				<Wrapper>
					<Helmet>
						<title>Community Association Survey | AppFolio Sites</title>
					</Helmet>
					<div className='d-flex align-items-start'>
						<Logo src={logo} width='75' alt='AppFolio Logo' className='mb-3 mb-lg-0' />
						<PageHeader heading='Community Association Survey' subheading='AppFolio Sites' />
					</div>
					<Form onSubmit={e => handleSubmit(e)}>
						{currentStep === 1 && <Welcome handleChange={handleChange} formResponse={formResponse} nextStep={nextStep} />}
						{currentStep === 2 && <PreliminaryInfo handleChange={handleChange} formResponse={formResponse} nextStep={nextStep} prevStep={prevStep} />}
						{currentStep >= 3 && currentStep <= 9 && <TemplateDesign handleChange={handleChange} formResponse={formResponse} nextStep={nextStep} prevStep={prevStep} currentStep={currentStep} />}
						{currentStep === 10 && <FinalApprover handleChange={handleChange} formResponse={formResponse} prevStep={prevStep} />}
						{currentStep === 'Complete' && <Complete formResponse={formResponse} />}
					</Form>
				</Wrapper>
			) : (
				<LoadingOverlay />
			)}
		</div>
	);
};

export default Survey;

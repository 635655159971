import { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import Linkify from 'react-linkify';
import { tidyUrl } from 'utils/Tidy';

const CheckList = ({ assets_url, assets, hideCompleted, hideButton }) => {
	// Component state
	const [filteredAssets, setFilteredAssets] = useState([]);

	useEffect(() => {
		if (hideCompleted) {
			const newAssets = assets.filter(asset => !asset.complete);
			setFilteredAssets(newAssets);
		} else {
			setFilteredAssets(assets);
		}
	}, [assets, hideCompleted]);

	// Component decorator for Linkify
	const componentDecorator = (href, text, key) => (
		<a href={href} key={key} target='_blank' rel='noopener noreferrer' className='text-sky-600'>
			{tidyUrl(text)}
		</a>
	);

	return (
		<div>
			{filteredAssets?.length > 0 ? (
				<>
					{filteredAssets.map(asset => (
						<p key={asset._id} className='my-2 d-flex align-items-start' style={{ lineHeight: 1.25, wordBreak: 'break-word' }}>
							{asset.complete ? <i className='mt-1 far fa-check-circle text-success me-2'></i> : <i className='mt-1 far fa-times-circle text-danger me-2'></i>}
							<span>
								<Linkify componentDecorator={componentDecorator}>{asset.name}</Linkify>
							</span>
						</p>
					))}

					{assets_url && !hideButton && (
						<Button className='mt-2' href={assets_url} target='_blank' rel='noopener noreferrer'>
							Upload Assets <i className='fas fa-upload'></i>
						</Button>
					)}
				</>
			) : (
				<p>No assets listed.</p>
			)}
		</div>
	);
};

export default CheckList;

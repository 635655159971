import { Calendar } from 'lucide-react';
import CheckList from './CheckList';
import { PopupModal, useCalendlyEventListener } from 'react-calendly';
import { useState } from 'react';
import { fetcher } from 'utils/Fetch';

const scheduleEventButtonStatus = (calendly, stage, showCalendly, designer) => {
	const { booking_url, is_enabled, events } = { ...calendly };
	if (!booking_url || !is_enabled) return false;

	if (!showCalendly) return false;
	if (stage !== 'Welcome' && stage !== 'Review') return false;

	if (!designer?.calendly?.access_token) return false;

	const kickOffEvent = events?.find(event => event.event_name === 'Kick Off Call' && event.status === 'active');
	const reviewEvent = events?.find(event => event.event_name === 'Review Call' && event.status === 'active');

	if ((stage === 'Welcome' && !kickOffEvent) || (stage === 'Review' && !reviewEvent)) return true;
	return false;
};

const WhatWeNeed = ({ plan, designer, showCalendly, mutate }) => {
	// Props
	const { stage, calendly, assets, assets_url } = { ...plan };
	const { booking_url } = { ...calendly };

	// State
	const [calendlyModalIsOpen, setCalendlyModalIsOpen] = useState(false);
	const showScheduleEventButton = scheduleEventButtonStatus(calendly, stage, showCalendly, designer);

	// Hooks
	useCalendlyEventListener({ onEventScheduled: e => handleEventSchedule(e) });

	// Handlers
	const handleEventSchedule = async e => {
		// Determine event
		let event_name = 'Kick Off Call';
		if (stage === 'Review') event_name = 'Review Call';

		try {
			// Submit
			const body = { access_token: designer.calendly.access_token, event_uri: e.data.payload.event.uri, event_name };
			await fetcher(`/api/v1/plans/${plan._id}/events`, { method: 'POST', body: JSON.stringify(body) });
			await mutate();
		} catch (error) {
			setCalendlyModalIsOpen(false);
		}
	};

	switch (stage) {
		case 'Welcome':
			return (
				<>
					{calendlyModalIsOpen && <PopupModal url={booking_url} onModalClose={() => setCalendlyModalIsOpen(false)} open={calendlyModalIsOpen} rootElement={document.getElementById('root')} />}
					<div className='space-y-4'>
						<ul className='list-disc list-inside'>
							<li>Schedule a project kickoff call</li>
							<li>Establish a project timeline</li>
							<li>Decide on a template</li>
							<li>Decide on a domain name</li>
						</ul>
						{showScheduleEventButton && (
							<button onClick={() => setCalendlyModalIsOpen(true)} className='flex items-center gap-2 px-3 py-2 text-white duration-100 rounded bg-sky-600 hover:bg-sky-700'>
								<div>Schedule your project kickoff call</div>
								<Calendar className='w-5 h-5 text-sky-200' />
							</button>
						)}
					</div>
				</>
			);

		case 'Collect':
			return <CheckList assets_url={assets_url} assets={assets} hideCompleted={false} />;

		case 'Build':
			return <CheckList assets_url={assets_url} assets={assets} hideCompleted={true} />;

		case 'Review':
			return (
				<>
					<PopupModal url={booking_url} onModalClose={() => setCalendlyModalIsOpen(false)} open={calendlyModalIsOpen} rootElement={document.getElementById('root')} />
					<div className='space-y-4'>
						<CheckList assets_url={assets_url} assets={assets} hideCompleted={true} />
						{showScheduleEventButton && (
							<button onClick={() => setCalendlyModalIsOpen(true)} className='flex items-center gap-2 px-3 py-2 text-white duration-100 rounded bg-sky-600 hover:bg-sky-700'>
								<div>Schedule your project review call</div>
								<Calendar className='w-5 h-5 text-sky-200' />
							</button>
						)}
					</div>
				</>
			);

		case 'Live':
			return <p>Nothing, your site is live!</p>;

		default:
			return null;
	}
};

export default WhatWeNeed;

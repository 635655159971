import { useState } from 'react';
import { Form, FormCheck } from 'react-bootstrap';
import { Draggable } from 'react-beautiful-dnd';
import styled from 'styled-components';

const Component = styled.div`
	display: flex;
	align-items: center;
	background: var(--lightest);
	border-bottom: 1px solid var(--bs-light);
	padding: 0.5rem 0.75rem;

	& .button-icon:hover {
		cursor: pointer;
	}
`;

const SingleAsset = ({ asset, index, handleToggle, handleDelete, handleEdit }) => {
	// Component state
	const [isEditing, setIsEditing] = useState(false);
	const [assetName, setAssetName] = useState(asset.name);

	// Handle edit asset
	const editAsset = (id, name) => {
		handleEdit(id, name);
		setIsEditing(false);
	};

	// Cancel edit asset
	const cancelEdit = () => {
		setAssetName(asset.name);
		setIsEditing(false);
	};

	return (
		<Draggable draggableId={asset._id} index={index}>
			{provided => (
				<Component ref={provided.innerRef} {...provided.draggableProps}>
					{!isEditing ? (
						<>
							<FormCheck id={asset._id}>
								<FormCheck.Input type='checkbox' defaultChecked={asset.complete} onClick={() => handleToggle(asset._id)} />
								<FormCheck.Label style={{ textDecoration: asset.complete ? 'line-through' : 'none' }}>{asset.name}</FormCheck.Label>
							</FormCheck>
							<i className='button-icon fas fa-pen ms-auto mt-1 text-primary' onClick={() => setIsEditing(true)}></i>
							<i className='button-icon fas fa-trash-alt ms-3 mt-1 text-danger' onClick={() => handleDelete(asset._id)}></i>
							<i {...provided.dragHandleProps} className='fas fa-grip-lines ms-3 mt-1 text-secondary'></i>
						</>
					) : (
						<>
							<Form.Control id={asset._id} value={assetName} onChange={e => setAssetName(e.target.value)} className='border-0 shadow-sm me-3' />
							<i className='button-icon fas fa-check ms-auto mt-1 text-success' onClick={() => editAsset(asset._id, assetName)}></i>
							<i className='button-icon fas fa-times ms-3 mt-1 text-danger' onClick={() => cancelEdit()}></i>
							<i {...provided.dragHandleProps} className='fas fa-grip-lines ms-3 mt-1 text-secondary'></i>
						</>
					)}
				</Component>
			)}
		</Draggable>
	);
};

export default SingleAsset;

import { fetcher } from './Fetch';

// Sign user in via Admin app
export const handleSignIn = async token => {
	const response = await fetch('/auth/signin', { headers: { Authorization: `Bearer ${token}` } });
	const profile = await response.json();
	if (!response.ok) throw new Error('Unauthorized');
	return profile;
};

// Check if user is signed in using previous session's cookie
export const checkIsSignedIn = async token => {
	const response = await fetch('/auth/validate', { headers: { Authorization: `Bearer ${token}` } });
	const profile = await response.json();
	if (!response.ok) throw new Error('Unauthorized');
	return profile;
};

// Fetch user profile & permissions from admin app
export const getGlobalUser = async profile => {
	const payload = { ...profile, appId: process.env.REACT_APP_PERMISSION_ID };
	const { permissions, ...user } = await fetcher('/api/v1/users', {
		method: 'POST',
		body: JSON.stringify(payload)
	});
	const { isAdmin, lastSignIn } = permissions.find(app => app.id === process.env.REACT_APP_PERMISSION_ID);
	const userProfile = { ...user, isAdmin, lastSignIn };
	return userProfile;
};

// Fetch user profile from local app
export const getLocalUser = async userId => await fetcher(`/api/v1/users/${userId}`);

// Create new user
export const createLocalUser = async profile => await fetcher(`/api/v1/users/new`, { method: 'POST', body: JSON.stringify({ ...profile, _id: profile.googleId, jobTitle: 'Web Designer', userLocation: 'Santa Barbara, CA.' }) });

// Upsert local user
export const checkLocalUser = async profile => {
	const user = await getLocalUser(profile._id);
	if (!user) {
		createLocalUser(profile);
	}
};

// Generate anonymous user session jwt
export const getJwt = async () => await fetcher('/auth/jwt');

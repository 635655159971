import { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import AssetList from './AssetList';
import ObjectID from 'bson-objectid';

const Assets = ({ assets, setAssets }) => {
	// Component level state
	const [newAsset, setNewAsset] = useState('');

	// Handle toggle of checked item
	const handleToggle = id => {
		const newAssets = [...assets];
		const asset = newAssets.find(asset => asset._id === id);
		asset.complete = !asset.complete;
		setAssets(newAssets);
	};

	const handleEnterKey = e => {
		if (e.key === 'Enter') {
			e.preventDefault();
			handleAdd();
		}
	};

	// Handle adding new item
	const handleAdd = () => {
		if (newAsset !== '') {
			const id = ObjectID().toHexString();
			const addAsset = {
				id,
				name: newAsset,
				complete: false
			};
			const newAssets = [addAsset, ...assets];
			setAssets(newAssets);
			setNewAsset('');
		}
	};

	const handleEdit = (id, name) => {
		const editAsset = assets.find(asset => asset._id === id);
		editAsset.name = name;
	};

	// Handle deleting item
	const handleDelete = id => {
		const newAssets = assets.filter(asset => asset._id !== id);
		setAssets(newAssets);
	};

	// Handle reorder list
	const handleReorder = (list, startIndex, endIndex) => {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);
		return result;
	};

	// Handle reorder list
	const handleDragEnd = result => {
		if (!result.destination) {
			return;
		}

		if (result.destination.index === result.source.index) {
			return;
		}

		const newAssets = handleReorder(assets, result.source.index, result.destination.index);
		setAssets(newAssets);
	};

	return (
		<>
			<div className='d-flex flex-nowrap mb-3'>
				<Form.Control
					value={newAsset}
					onChange={e => {
						setNewAsset(e.target.value);
					}}
					onKeyDown={handleEnterKey}
					type='text'
					placeholder='Add an item'
					className='border-0 shadow-sm w-100'
				/>
				<Button
					className='default-style ms-2'
					onClick={() => {
						handleAdd();
					}}
					title='Add item to checklist'>
					<i className='fas fa-plus'></i>
				</Button>
			</div>
			<DragDropContext onDragEnd={handleDragEnd}>
				<Droppable droppableId='list'>
					{provided => (
						<div ref={provided.innerRef} {...provided.droppableProps}>
							<AssetList assets={assets} handleToggle={handleToggle} handleDelete={handleDelete} handleEdit={handleEdit} />
							{provided.placeholder}
						</div>
					)}
				</Droppable>
			</DragDropContext>
		</>
	);
};

export default Assets;
